import { getCakeList } from "./helper/storeHelper";

export const SET_CART_ANIMATION = "order/SET_CART_ANIMATION";
export const setCartAnimation = () => ({ type: SET_CART_ANIMATION });

export const RECIEVE = "RECIEVE";
export const receiveApiData = (data) => ({ type: RECIEVE, data });

export const SET_BUTTON_CONTENT_ANIMATION = "order/SET_BUTTON_CONTENT_ANIMATION";
export const setButtonContentAnimation = (index) => ({
    type: SET_BUTTON_CONTENT_ANIMATION,
    index: index
});

export const SET_CART_POPUP_OPEN = "order/SET_CART_POPUP_OPEN";
export const setCartPopupOpen = () => ({ type: SET_CART_POPUP_OPEN });

export const SET_EDIT_ORDER_RELOADING = "order/SET_EDIT_ORDER_RELOADING";
export const editOrderFormReLoadingAction = () => ({
    type: SET_EDIT_ORDER_RELOADING
});

export const SET_CART = "order/SET_CART";
export const setCart = (cart) => (dispatch) =>
    new Promise((resolve) => {
        dispatch({
            type: SET_CART,
            cart: cart
        });
        resolve({});
    });

export const DELETE_CART = "order/DELETE_CART";
export const deleteCart = (key) => (dispatch) =>
    new Promise((resolve) => {
        dispatch({
            type: DELETE_CART,
            key: key
        });
        resolve({});
    });

export const SET_SUBMITTED_LOADER = "SET_SUBMITTED_LOADER";
export const setOrderSubmitted = (value) => ({
    type: SET_SUBMITTED_LOADER,
    data: value
});

const initialState = {
    cakeList: getCakeList(),
    cart: {},
    loading: true,
    cartAnimated: false,
    bottonContentAnimated: -1,
    editOrderFormReLoading: false,
    cartPopupOpen: false,
    orderSubmitted: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CART:
            return {
                ...state,
                cart: { ...action.cart }
            };
        case SET_CART_ANIMATION:
            return { ...state, cartAnimated: !state.cartAnimated };
        case SET_BUTTON_CONTENT_ANIMATION:
            return { ...state, bottonContentAnimated: action.index };
        case RECIEVE:
            return {
                ...state,
                submitted: true,
                loading: false,
                data: action.data,
                cart: {}
            };
        case SET_CART_POPUP_OPEN:
            return { ...state, cartPopupOpen: !state.cartPopupOpen };
        case DELETE_CART:
            delete state.cart[action.key];
            return { ...state };
        case SET_EDIT_ORDER_RELOADING:
            return {
                ...state,
                editOrderFormReLoading: !state.editOrderFormReLoading
            };
        case SET_SUBMITTED_LOADER:
            return {
                ...state,
                orderSubmitted: action.data
            };
        default:
            return state;
    }
};
