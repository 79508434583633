import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createBrowserHistory, createMemoryHistory } from "history";
import rootReducer from "./modules";
import mySaga from "./app/sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// A nice helper to tell us if we're on the server
export const isServer = !(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
);

export default (url = "/") => {
    // Create a history depending on the environment
    const history = isServer
        ? createMemoryHistory({
              initialEntries: [url]
          })
        : createBrowserHistory();

    const enhancers = [];

    // Dev tools are helpful
    if (process.env.NODE_ENV === "development" && !isServer) {
        if (
            typeof window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        ) {
            enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
        }
    }

    const middleware = [thunk, sagaMiddleware, routerMiddleware(history)];
    const composedEnhancers = compose(
        applyMiddleware(...middleware),
        ...enhancers
    );

    // Do we have preloaded state available? Great, save it.
    const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

    // Delete it once we have it stored in a variable
    if (!isServer) {
        delete window.__PRELOADED_STATE__;
    }

    const createRootReducer = (history) =>
        combineReducers({
            router: connectRouter(history),
            ...rootReducer
        });

    // Create the store
    const store = createStore(
        createRootReducer(history),
        initialState,
        composedEnhancers
    );

    // then run the saga
    sagaMiddleware.run(mySaga);

    return {
        store,
        history
    };
};
