import React from "react";
import Divider from "@material-ui/core/Divider";
import { useSelector, useDispatch } from "react-redux";
import { DEFAULT } from "../../../helper/orderCakeHelper";
import {
    removeItem,
    editItemQuantity
} from "../../../../modules/actions/shoppingCart.action";
import "./shoppingCartItemsCmt.scss";

export default function ShoppingCartItemsCmt() {
    const items = useSelector((state) => state.shoppingCart.items);
    const calculateSubtotal = () => {
        let subtotal = 0;
        for (let key in items) {
            subtotal = subtotal + items[key].quantity * parseFloat(items[key].price);
        }

        return subtotal.toFixed(2);
    };
    return (
        <div id="shopping-cart-item-id">
            <div className="shopping-cart-items__container">
                {Object.keys(items).map((itemKey) => {
                    return (
                        <CartItem key={itemKey} item={items[itemKey]} itemKey={itemKey} />
                    );
                })}
            </div>
            <div className="shopping-cart-items__subtotal">
                <div className="shopping-cart-items__subtotal__title-text">Subtotal</div>
                <div className="shopping-cart-items__subtotal__total-price">
                    ${calculateSubtotal()}
                </div>
            </div>
        </div>
    );
}

const CartItem = ({ item, itemKey }) => {
    const [quantity, setQuantity] = React.useState(item.quantity);
    const dispatch = useDispatch();
    const onClickRemoveItemHandler = () => {
        dispatch(removeItem(itemKey));
    };

    const onBlurQuantityInputHandler = (e) => {
        if (e.target.value <= 0) {
            dispatch(removeItem(itemKey));
        } else {
            dispatch(editItemQuantity(itemKey, e.target.value));
        }
    };
    const onChangeQuantityInputHandler = (e) => {
        setQuantity(e.target.value);
    };
    return (
        <React.Fragment>
            <div className="shopping-cart-item__container">
                <div className="shopping-cart-items__container__image">
                    <img
                        src={`/cakes${item.img_url}`}
                        style={{
                            maxWidth: "115px",
                            maxHeight: "115px",
                            width: "100%",
                            height: "auto"
                        }}
                        width="115px"
                        height="115px"
                    />
                </div>
                <div className="shopping-cart-items__container__product-title">
                    {item.name}
                </div>
                <div className="shopping-cart-items__container__variations">
                    <div className="shopping-cart-items__container__variations__product-option">
                        Size: {item.size}
                    </div>
                    {item.flavor1 &&
                        item.flavor1 != DEFAULT && (
                            <div className="shopping-cart-items__container__variations__product-flavor">
                                flavor 1: {item.flavor1}
                            </div>
                        )}
                    {item.flavor2 &&
                        item.flavor2 != DEFAULT && (
                            <div className="shopping-cart-items__container__variations__product-second-flavor">
                                flavor 2: {item.flavor2}
                            </div>
                        )}
                </div>
                <div className="shopping-cart-items__container__quantity">
                    <input
                        value={quantity}
                        type="number"
                        onChange={onChangeQuantityInputHandler}
                        onBlur={onBlurQuantityInputHandler}
                    />
                </div>
                <div className="shopping-cart-items__container__price">${item.price}</div>
                <div className="shopping-cart-items__container__remove-button">
                    <button onClick={onClickRemoveItemHandler}>Remove</button>
                </div>
            </div>
            <Divider />
        </React.Fragment>
    );
};
