export const SET_ORDER_LIMIT = "SET_ORDER_LIMIT";
export const SET_ORDER_LIMIT_MAPPER = "SET_ORDER_LIMIT_MAPPER";
export const SET_PICKUP_TIME = "SET_PICKUP_TIME";
export const GET_ORDER_LIMIT_FAIL = "GET_ORDER_LIMIT_FAIL";
export const SET_LOCATION = "SET_LOCATION";

export const setOrderCakeState = (payload) => ({
    type: SET_ORDER_LIMIT_MAPPER,
    data: payload
});
export const setPickupDate = (payload) => ({
    type: SET_PICKUP_TIME,
    data: payload
});
export const setOrderLimit = (payload) => ({
    type: SET_ORDER_LIMIT,
    data: payload
});
export const setLocation = (location) => ({
    type: SET_LOCATION,
    location: location
});
