import { convertLocalTimeToPST, isPSTTimeZone } from "../../app/common/common";
import { getLocationFromLocalStorage, SUNNYVALE } from "../../app/helper/locationHelper";
import {
    SET_ORDER_LIMIT,
    SET_ORDER_LIMIT_MAPPER,
    GET_ORDER_LIMIT_FAIL,
    SET_PICKUP_TIME,
    SET_LOCATION
} from "../actions/orderCake.action";

const defaultOrderCakeLimitState = {
    orderLimit: {
        crepe_cake: {
            allow_count: undefined,
            allow_type: undefined
        },
        box_cake: {
            allow_count: undefined,
            allow_type: undefined
        },
        chiffon_cake: {
            allow_count: undefined,
            allow_type: undefined
        },
        sessional_cake: {
            allow_count: undefined,
            allow_type: undefined
        }
    },
    orderItems: {},
    orderLimitTimestampMapper: {},
    location: getLocationFromLocalStorage(),
    pickupDate: undefined,
    loading: false,
    error: undefined
};

export default (state = defaultOrderCakeLimitState, action) => {
    switch (action.type) {
        case SET_LOCATION:
            return {
                ...state,
                ...action.data,
                location: action.location
            };

        case SET_ORDER_LIMIT_MAPPER:
            return {
                ...state,
                ...action.data,
                loading: false,
                error: undefined
            };

        case GET_ORDER_LIMIT_FAIL:
            return {
                ...state,
                orderLimit: {
                    crepe_cake: {},
                    box_cake: {},
                    chiffon_cake: {},
                    sessional_cake: {}
                },
                orderLimitMapper: {},
                loading: false,
                error: "Please try it again later."
            };
        case SET_ORDER_LIMIT:
            return {
                ...state,
                orderLimit: action.data.orderLimit
            };
        case SET_PICKUP_TIME:
            let pickupTimestamp = JSON.stringify(action.data.pickupDate.getTime() / 1000);
            if (!isPSTTimeZone()) {
                pickupTimestamp = JSON.stringify(
                    convertLocalTimeToPST(action.data.pickupDate)
                );
            }
            window.sessionStorage.setItem("orderCakeReducerPickupDate", pickupTimestamp);
            return {
                ...state,
                pickupDate: action.data.pickupDate
            };
        default:
            return state;
    }
};
