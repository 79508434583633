export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const EDIT_ITEM = "EDIT_ITEM";
export const REMOVE_ALL_ITEM = "REMOVE_ALL_ITEM ";
export const RESTORE_ITEMS = "RESTORE_ITEMS";
export const TOGGLE_SHOPPING_CART_DRAWER = "TOGGLE_SHOPPING_CART_DRAWER";

export const addItem = ({ itemKey, item }) => ({
    type: ADD_ITEM,
    payload: {
        itemKey: itemKey,
        item: item
    }
});

export const removeItem = (itemKey) => ({
    type: REMOVE_ITEM,
    payload: {
        itemKey: itemKey
    }
});

export const editItemQuantity = (itemKey, quantity) => ({
    type: EDIT_ITEM,
    payload: {
        itemKey: itemKey,
        quantity: quantity
    }
});

export const restoreItemsFromSessionStore = () => ({
    type: RESTORE_ITEMS
});

export const removeAllItems = () => ({
    type: REMOVE_ALL_ITEM
});

export const toggleShoppingCartDrawer = (open) => ({
    type: TOGGLE_SHOPPING_CART_DRAWER,
    payload: {
        open: open
    }
});
