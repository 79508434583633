import {
    ADD_ITEM,
    REMOVE_ITEM,
    EDIT_ITEM,
    RESTORE_ITEMS,
    REMOVE_ALL_ITEM,
    TOGGLE_SHOPPING_CART_DRAWER
} from "../actions/shoppingCart.action";

const defaultShoppingCartState = {
    items: {},
    isShoppingCartDrawerOpen: false,
    loading: false,
    error: undefined
};

export default (state = defaultShoppingCartState, action) => {
    switch (action.type) {
        case ADD_ITEM:
            const newItem = {
                [action.payload.itemKey]: action.payload.item
            };
            if (state.items.hasOwnProperty(action.payload.itemKey)) {
                const prevItem = state.items[action.payload.itemKey];
                delete state.items[action.payload.itemKey];
                newItem[action.payload.itemKey].quantity =
                    parseInt(newItem[action.payload.itemKey].quantity) +
                    parseInt(prevItem.quantity);
                newItem[action.payload.itemKey].total =
                    parseInt(newItem[action.payload.itemKey].quantity) *
                    parseInt(newItem[action.payload.itemKey].price);

                // Save data to sessionStorage
                window.sessionStorage.setItem(
                    "shoppingCartState",
                    JSON.stringify({
                        ...state,
                        items: { ...state.items, ...newItem }
                    })
                );
                return { ...state, items: { ...state.items, ...newItem } };
            } else {
                window.sessionStorage.setItem(
                    "shoppingCartState",
                    JSON.stringify({
                        ...state,
                        items: { ...state.items, ...newItem }
                    })
                );
                return { ...state, items: { ...state.items, ...newItem } };
            }

        case REMOVE_ITEM:
            delete state.items[action.payload.itemKey];
            window.sessionStorage.setItem(
                "shoppingCartState",
                JSON.stringify({ ...state, items: { ...state.items } })
            );
            return { ...state, items: { ...state.items } };
        case EDIT_ITEM:
            state.items[action.payload.itemKey].quantity = parseInt(
                action.payload.quantity
            );
            state.items[action.payload.itemKey].total =
                parseInt(state.items[action.payload.itemKey].quantity) *
                parseInt(state.items[action.payload.itemKey].price);
            window.sessionStorage.setItem(
                "shoppingCartState",
                JSON.stringify({
                    ...state,
                    items: { ...state.items }
                })
            );
            return {
                ...state,
                items: { ...state.items }
            };
        case RESTORE_ITEMS:
            const shoppingCartState = JSON.parse(
                window.sessionStorage.getItem("shoppingCartState")
            );
            if (shoppingCartState) {
                return { ...shoppingCartState };
            }
        case REMOVE_ALL_ITEM:
            window.sessionStorage.setItem(
                "shoppingCartState",
                JSON.stringify({
                    ...state,
                    items: {}
                })
            );
            return {
                ...state,
                items: {}
            };
        case TOGGLE_SHOPPING_CART_DRAWER:
            return {
                ...state,
                isShoppingCartDrawerOpen: action.payload.open
            };
        default:
            return state;
    }
};
