import React from "react";
import ShoppingCart from "./components/ShoppingCart/ShoppingCart";
import { NavLink } from "react-router-dom";

const links = [
    {
        to: "/",
        text: "Home"
    },
    // {
    //     to: "/order",
    //     text: "Order",
    // },
    {
        to: "/story",
        text: "Our Story"
    },
    {
        to: "/order_cake",
        text: "Dessert"
    },
    {
        to: "/celebration_cake",
        text: "Celebration Cake"
    },
    {
        to: "https://www.instagram.com/whatsmorecakeshop/",
        text: "Gallery",
        external: true
    }
    // {
    //   to: '/profile/2',
    //   text: 'Profile 2'
    // },
    // {
    //   to: '/login',
    //   text: 'Login',
    //   auth: false
    // },
    // {
    //   to: '/dashboard',
    //   text: 'Dashboard',
    //   auth: true
    // },
    // {
    //   to: '/logout',
    //   text: 'Logout',
    //   auth: true
    // },
    // {
    //   to: '/this-is-broken',
    //   text: 'Broken Page'
    // }
];

const isCurrent = (to, current) => {
    if (to === "/" && current === to) {
        return true;
    } else if (to !== "/" && current.includes(to)) {
        return true;
    }

    return false;
};

const HeaderLink = ({ to, text, current, external, setSideDrawer }) => (
    <li
        className={isCurrent(to, current) ? "current" : ""}
        onClick={() => setSideDrawer()}
    >
        {external ? (
            <a rel="noopener noreferrer" target="_blank" href={to}>
                {text}
            </a>
        ) : (
            <NavLink to={to}>{text}</NavLink>
        )}
    </li>
);

export default ({ isAuthenticated, current, isSideDrawerOpen, setSideDrawer }) => (
    <React.Fragment>
        <div
            id="hamberger"
            className={isSideDrawerOpen ? "side-drawer-opened" : ""}
            onClick={() => setSideDrawer()}
        >
            <i className="fas fa-bars fa-lg" />
        </div>
        <header id="header">
            <img
                src={"/logo/whatsmore-homepage-logo.png"}
                alt="Homepage"
                width="250px"
                style={{
                    width: "250px",
                    display: "inline-block",
                    minHeight: "68px"
                }}
            />
            <ul id="links" className={isSideDrawerOpen ? "side-drawer-opened" : ""}>
                {links.map((link, index) => {
                    // Don't want to open the sideDraw if isSideDrawOpen is false
                    const TheLink = (
                        <HeaderLink
                            key={index}
                            current={current}
                            {...link}
                            setSideDrawer={isSideDrawerOpen ? setSideDrawer : () => {}}
                        />
                    );

                    if (link.hasOwnProperty("auth")) {
                        if (link.auth && isAuthenticated) {
                            return TheLink;
                        } else if (!link.auth && !isAuthenticated) {
                            return TheLink;
                        }

                        return null;
                    }

                    return TheLink;
                })}
            </ul>
            <div className="header-shopping-cart-wrapper">
                <ShoppingCart />
            </div>
        </header>
    </React.Fragment>
);
