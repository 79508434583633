import axios from "axios";
import {
    selectNearestAvailableTime,
    isSelectedDateAvailable
} from "../helper/orderCakeHelper";
import {
    convertUTCTimestampToYYMMDD,
    getYYMMDDFromNow,
    convertDateToTimestamp,
    isPSTTimeZone,
    convertLocalTimeToPST
} from "../common/common";

// Add a request interceptor
axios.interceptors.request.use(function(config) {
    try {
        if (process.env.NODE_ENV === "production" && window.csrfToken) {
            config.headers["CSRF-Token"] = window.csrfToken;
        }
    } catch (error) {
        console.log("[!] Unable to set CSRF token in AXIOS's header:", error);
    }

    return config;
});

export const fetchData = async () => {
    try {
        const response = await axios.get("/api/cake_limit_by_date/2019-07-08");
        console.log(response.data);
        return response.data;
    } catch (e) {
        console.log(e);
    }
};

export const postCheckOutApi = async (checkoutRequestBody) => {
    try {
        const response = await axios.post("/api/orders/save", checkoutRequestBody);
        const data = {
            status: response.status,
            ...response.data
        };
        return data;
    } catch (e) {
        console.log(e);
    }
};

export const getOrderLimitApi = async (pickupTimestamp, days, location) => {
    try {
        const response = await axios.get(
            `/api/orderLimit/getOrderLimitDaysFromNow/?timestamp=${pickupTimestamp}&days=${days}&locations[0]=${location}`
        );

        const orderLimits = response.data;
        const orderLimitTimestampMapper = {};

        for (let i in orderLimits) {
            orderLimitTimestampMapper[orderLimits[i].timestamp] = orderLimits[i];
        }

        // customer can only order crepe cake in 2 day advance
        const oneDayFromCurrent = isPSTTimeZone()
            ? convertDateToTimestamp(getYYMMDDFromNow(1))
            : convertLocalTimeToPST(getYYMMDDFromNow(1));

        if (orderLimitTimestampMapper[oneDayFromCurrent]) {
            // commented out 1 day advance
            // orderLimitTimestampMapper[oneDayFromCurrent]["crepe_cake"]["allow_count"] = 0;
        }

        let { pickupDate, timestamp } = selectNearestAvailableTime(orderLimits);

        const pickupTimestampFromSession = JSON.parse(
            window.sessionStorage.getItem("orderCakeReducerPickupDate")
        );

        if (
            isSelectedDateAvailable(pickupTimestampFromSession, orderLimitTimestampMapper)
        ) {
            pickupDate = new Date(
                convertUTCTimestampToYYMMDD(pickupTimestampFromSession * 1000)
            );
            timestamp = pickupTimestampFromSession;
        }

        return {
            orderLimitTimestampMapper,
            pickupDate: pickupDate,
            orderLimit: orderLimitTimestampMapper[timestamp]
        };
    } catch (e) {
        console.log(e);
        throw e;
    }
};
