import { reducer as formReducer } from "redux-form";

import auth from "./auth";
import profile from "./profile";
import app from "./app";
import order from "./order";
import orderCakeReducer from "./reducers/orderCake.reducer";
import shoppingCartReducer from "./reducers/shoppingCart.reducer";
import configsReducer from "./reducers/configs.reducer";
export default {
    auth,
    profile,
    app,
    order,
    orderCake: orderCakeReducer,
    shoppingCart: shoppingCartReducer,
    configs: configsReducer,
    form: formReducer
};
