import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Container from "@material-ui/core/Container";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import ShoppingCartItems from "./ShoppingCartItemsCmt/ShoppingCartItemsCmt";
import { toggleShoppingCartDrawer } from "../../../../src/modules/actions/shoppingCart.action";
import { useSelector, useDispatch } from "react-redux";
import { getObjectKeysLength } from "../../common/common";
import "./shoppingCart.scss";

export default function ShoppingCart() {
    const dispatch = useDispatch();
    const history = useHistory();
    const items = useSelector((state) => state.shoppingCart.items);
    const isShoppingCartDrawerOpen = useSelector(
        (state) => state.shoppingCart.isShoppingCartDrawerOpen
    );

    const isCartEmpty = getObjectKeysLength(items) < 1;

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        dispatch(toggleShoppingCartDrawer(open));
    };

    const onClickSubmitCheckoutHandler = () => {
        dispatch(toggleShoppingCartDrawer(false));
        history.push("/checkout");
    };

    const onClickContinueShoppingHandler = () => {
        dispatch(toggleShoppingCartDrawer(false));
        const pathname = history.location.pathname;
        if (!pathname.split("/").includes("order_cake")) {
            history.push("/order_cake");
        }
    };

    const Checkout = () => (
        <React.Fragment>
            <div id="shopping-cart-id">
                <div className="shopping-cart">
                    <div className="shopping-cart__header">
                        <Container>
                            <div className="shopping-cart__header__container">
                                <div className="shopping-cart__header__container__title-text">
                                    Shopping Cart
                                </div>
                                <div className="shopping-cart__close-icon">
                                    <CloseIcon
                                        fontSize="small"
                                        onClick={toggleDrawer(false)}
                                    />
                                </div>
                            </div>
                        </Container>
                        <Divider />
                    </div>
                    <div className="shopping-cart__body">
                        <Container>
                            {isCartEmpty ? (
                                <div className="shopping-cart__body-empty-content">
                                    You don't have any items in your cart.
                                </div>
                            ) : (
                                <ShoppingCartItems />
                            )}
                        </Container>
                    </div>
                    {!isCartEmpty && (
                        <div className="shopping-cart__footer">
                            <Container>
                                <button
                                    onClick={onClickSubmitCheckoutHandler}
                                    className="shopping-cart__footer__checkout-btn"
                                >
                                    Checkout
                                </button>
                                <button
                                    onClick={onClickContinueShoppingHandler}
                                    className="shopping-cart__footer__continue-shopping-btn"
                                >
                                    Continue Shopping
                                </button>
                            </Container>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div>
                <IconButton
                    edge="start"
                    color="default"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                >
                    <ShoppingCartIcon />{" "}
                    <span style={{ fontSize: "18px" }}>
                        {getObjectKeysLength(items) > 0 && getObjectKeysLength(items)}
                    </span>
                </IconButton>
                <SwipeableDrawer
                    anchor={"right"}
                    open={isShoppingCartDrawerOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Checkout />
                </SwipeableDrawer>
            </div>
        </React.Fragment>
    );
}
