// The basics
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

// Action creators and helpers
import { establishCurrentUser } from "../modules/auth";
import { setSideDrawer } from "../modules/app";
import { isServer } from "../store";

import Header from "./header";
import Routes from "./routes";
import Footer from "./footer";
import "./app.scss";

const show = (to, current) => {
    const split = current.split("/");
    if (split[1] === "") {
        return "show";
    }

    if (split[1] === "order") {
        return "show";
    }

    return "hidden";
};

class App extends Component {
    componentDidMount() {
        window.localStorage.removeItem("location");
    }
    componentWillMount() {
        if (!isServer) {
            this.props.establishCurrentUser();
        }
    }

    render() {
        return (
            <div id="app">
                <Header
                    isAuthenticated={this.props.isAuthenticated}
                    current={this.props.location.pathname}
                    isSideDrawerOpen={this.props.isSideDrawerOpen}
                    setSideDrawer={this.props.setSideDrawer}
                />
                {!isServer && (
                    <>
                        <div>
                            <div id="content">
                                <Routes />
                            </div>
                            <div id="footer">
                                <Footer />
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isSideDrawerOpen: state.app.isSideDrawerOpen,
    cart: state.order.cart,
    cartAnimated: state.order.cartAnimated
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ establishCurrentUser, setSideDrawer }, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
