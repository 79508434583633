import moment from "moment-timezone";

export const formattedNumber = (number) => {
    return ("0" + number).slice(-2);
};
export const minOrderDate = () => {
    const losAngeles = moment.tz("America/Los_Angeles");
    losAngeles.add(3, "day");
    return losAngeles.format("YYYY-MM-DD");
};
export const maxOrderDate = () => {
    const losAngeles = moment.tz("America/Los_Angeles");
    losAngeles.add(90, "day");
    return losAngeles.format("YYYY-MM-DD");
};
export const getScreenHeight = () => {
    try {
        const body = document.body,
            html = document.documentElement;
        const height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );
        return height;
    } catch (error) {}
};
export const getScreenWidth = () => {
    try {
        const body = document.body,
            html = document.documentElement;
        const width = Math.max(
            body.scrollWidth,
            body.offsetWidth,
            html.clientWidth,
            html.scrollWidth,
            html.offsetWidth
        );
        return width;
    } catch (error) {}
};
export const isMobile = () => {
    if (getScreenWidth() < 700) {
        return true;
    }
    return false;
};

export const convertDateToTimestamp = (date) => {
    const losAngeles = moment.tz(date, "MM/DD/YYYY", "America/Los_Angeles");
    const timestamp = parseInt(losAngeles.valueOf() / 1000);
    return timestamp;
};

// 15898639485 -> 1589785200 = May. 18, 2020 07:00:00
export const convertUTCTimestampToTimestamp = (timestamp) => {
    const pst = moment.tz(timestamp, "America/Los_Angeles");
    const date = pst.date();
    const month = pst.month() + 1;
    const year = pst.year();
    const YYMMDD = month + "/" + date + "/" + year;
    return convertDateToTimestamp(YYMMDD);
};

// 15898639485 -> 1589785200 = May/18/020
export const convertUTCTimestampToYYMMDD = (timestamp) => {
    const pst = moment.tz(timestamp, "America/Los_Angeles");
    const date = pst.date();
    const month = pst.month() + 1;
    const year = pst.year();
    const YYMMDD = month + "/" + date + "/" + year;

    return YYMMDD;
};

export const getYYMMDDFromNow = (days) => {
    const utc = moment.tz("UTC");
    utc.add(days, "day");
    const YYMMDD = convertUTCTimestampToYYMMDD(utc.unix() * 1000);
    return new Date(YYMMDD);
};

export const getArraysOfTimestampsFromNow = (now, days) => {
    const timestamp = convertUTCTimestampToTimestamp(now * 1000);
    let timestamps = [];
    for (let day = 0; day < days; day++) {
        const utc = moment.tz(timestamp * 1000, "America/Los_Angeles");
        utc.add(day, "day");
        timestamps.push(utc.unix());
    }
    return timestamps;
};

// return true if the date is monday or sunday
export const isCheckMondaySunday = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    return day === 0 || day === 1;
};

export const isMondayOrSunday = (timestamp) => {
    const date = new Date(convertUTCTimestampToYYMMDD(timestamp * 1000));
    return isCheckMondaySunday(date);
};

export const isMondayFromDate = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    return day === 1;
};

export const isMondayFromTimestamp = (timestamp) => {
    const date = new Date(convertUTCTimestampToYYMMDD(timestamp * 1000));
    const d = new Date(date);
    const day = d.getDay();
    return day === 1;
};

export const getObjectKeysLength = (obj) => {
    try {
        return Object.keys(obj).length;
    } catch (error) {
        return 0;
    }
};

export const isPSTTimeZone = () => {
    const PstUTCOffset = moment.tz("America/Los_Angeles").utcOffset();
    const localUTCOffset = moment().utcOffset();
    if (PstUTCOffset === localUTCOffset) {
        return true;
    }
    return false;
};

export const convertLocalTimeToPST = (date) => {
    const PstUTCOffset = moment.tz(date, "America/Los_Angeles").utcOffset();
    const localUTCOffset = moment().utcOffset();
    const offset = (localUTCOffset - PstUTCOffset) * 60;
    const m = moment(date).tz("UTC");
    return m.unix() + offset;
};
