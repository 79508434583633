const cakes = [
  {
    _id: "5d33a7d1053a912d722bad61",
    created_time: "2019-07-20T23:46:25.691Z",
    cake_name: "Tiramisu Crepe Cake",
    category: "crepe_cakes",
    images: ["Tiramisu_Crepe_Cake"],
    sizes: [
      {
        price: 35,
        size: "half 6"
      },
      {
        price: 65,
        size: "6"
      },
      {
        price: 95,
        size: "8"
      }
    ],
    c_name: "提拉米苏千层"
  },
  {
    _id: "5d33a7b5053a912d722bad60",
    created_time: "2019-07-20T23:45:57.728Z",
    cake_name: "Matcha Crepe Cake",
    category: "crepe_cakes",
    images: ["Matcha_Crepe_Cake"],
    sizes: [
      {
        price: 35,
        size: "half 6"
      },
      {
        price: 65,
        size: "6"
      },
      {
        price: 95,
        size: "8"
      }
    ],
    c_name: "抹茶千层"
  },
  {
    _id: "5d33a7fc053a912d722bad63",
    created_time: "2019-07-20T23:47:08.465Z",
    cake_name: "Durian Crepe Cake",
    images: ["Durian_Crepe_Cake"],
    category: "crepe_cakes",
    sizes: [
      {
        price: 35,
        size: "half 6"
      },
      {
        price: 65,
        size: "6"
      },
      {
        price: 95,
        size: "8"
      }
    ],
    c_name: "榴莲千层"
  },
  {
    _id: "5d47bd517c213e60b8eb4c81",
    created_time: "2019-07-20T23:47:08.465Z",
    cake_name: "Red Bean Peanut Mochi Crepe Cake",
    category: "crepe_cakes",
    images: ["Red_Bean_Peanut_Mochi_Crepe_Cake"],
    sizes: [
      {
        price: 35,
        size: "half 6"
      },
      {
        price: 65,
        size: "6"
      },
      {
        price: 95,
        size: "8"
      }
    ],
    c_name: "红豆花生麻薯千层"
  },
  {
    _id: "5d47bf567c213e60b8eb4cdf",
    created_time: "2019-07-20T23:47:08.465Z",
    cake_name: "Salted Yolk Pork Floss Crepe Cake",
    category: "crepe_cakes",
    images: ["Salted_Yolk_Pork_Floss_Crepe_Cake"],
    sizes: [
      {
        price: 35,
        size: "half 6"
      },
      {
        price: 65,
        size: "6"
      },
      {
        price: 95,
        size: "8"
      }
    ],
    c_name: "咸蛋黄肉松千层"
  },
  {
    _id: "5ec171b35a1acc4d9998aa93",
    created_time: "2020-05-17T17:17:39.116Z",
    cake_name: "Mix Match 2 Flavors Crepe Cake",
    images: ["Mix_Match_2_Flavors_Crepe_Cake"],
    category: "mix_match",
    sizes: [
      {
        price: 70,
        size: "6"
      },
      {
        price: 105,
        size: "8"
      }
    ],
    c_name: "混合搭配千层"
  },
  {
    _id: "5ec175cf3a6fe4529b34bdcc",
    created_time: "2020-05-17T17:35:11.889Z",
    cake_name: "Soy Milk Custard Crepe Cake",
    category: "crepe_cakes",
    images: ["Soy_Milk_Custard_Crepe_Cake"],
    sizes: [
      {
        price: 35,
        size: "half 6"
      },
      {
        price: 65,
        size: "6"
      },
      {
        price: 95,
        size: "8"
      }
    ],
    c_name: "豆乳千层"
  }
];

export const getCakeList = () => {
  return sortByCategory(cakes);
};

function sortByCategory(cakes) {
  const sort = {
    boxed_cakes: [],
    mousse_cakes: [],
    chiffon_cakes: [],
    crepe_cakes: [],
    mix_match: [],
    decoration: []
  };
  for (let cake of cakes) {
    sort[cake.category].push(cake);
  }

  const result = [];
  result.push(...sort.boxed_cakes);
  result.push(...sort.mousse_cakes);
  result.push(...sort.chiffon_cakes);
  result.push(...sort.crepe_cakes);
  result.push(...sort.mix_match);
  result.push(...sort.decoration);

  return result;
}
