export const SET_SIDE_DRAWER = 'app/SET_SIDE_DRAWER';

const initialState = {
  isMobile: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDE_DRAWER:
      return {
        ...state,
        isSideDrawerOpen: !state.isSideDrawerOpen
      };
    default:
      return state;
  }
};

export const setSideDrawer = () => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_SIDE_DRAWER,
    });
    resolve({});
  });