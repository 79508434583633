import { call, put, takeLatest } from "redux-saga/effects";
import { receiveApiData, setOrderSubmitted } from "../../modules/order";
import {
    setOrderCakeState,
    GET_ORDER_LIMIT_FAIL
} from "../../modules/actions/orderCake.action";

import { fetchData, postCheckOutApi, getOrderLimitApi } from "./api";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchUser(action) {
    try {
        const data = yield call(fetchData);
        yield put(receiveApiData(data));
    } catch (e) {
        console.log("fail", e);
        yield put({ type: "USER_FETCH_FAILED", message: e.message });
    }
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* postCheckout(action) {
    try {
        const data = yield call(postCheckOutApi, action.payload);
        yield put(receiveApiData(data));
        yield put(setOrderSubmitted(false));
    } catch (e) {
        console.log("fail", e);
        yield put({ type: "USER_FETCH_FAILED", message: e.message });
    }
}

function* getOrderLimit(action) {
    try {
        const { pickupTimestamp, days, location } = action.payload;
        const data = yield call(getOrderLimitApi, pickupTimestamp, days, location);
        yield put(setOrderCakeState(data));
    } catch (e) {
        console.log("fail", e);
        yield put({ type: GET_ORDER_LIMIT_FAIL });
    }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* mySaga() {
    yield takeLatest("CHECKOUT_POST_REQUEST", postCheckout);
    yield takeLatest("GET_ORDER_LIMIT_REQUEST", getOrderLimit);
}

export default mySaga;
