import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import AuthenticatedRoute from "../components/authenticated-route";
import UnauthenticatedRoute from "../components/unauthenticated-route";
import Loadable from "react-loadable";

import NotFound from "./not-found";

const Homepage = Loadable({
    loader: () => import(/* webpackChunkName: "homepage" */ "./homepage"),
    loading: () => null,
    modules: ["homepage"],
    webpack: () => [require.resolveWeak("./homepage")]
});

const Order = Loadable({
    loader: () => import(/* webpackChunkName: "order" */ "./order"),
    loading: () => null,
    modules: ["order"],
    webpack: () => [require.resolveWeak("./order")]
});

const CelebrationCake = Loadable({
    loader: () => import(/* webpackChunkName: "celebration_cake" */ "./celebration_cake"),
    loading: () => null,
    modules: ["celebration_cake"],
    webpack: () => [require.resolveWeak("./celebration_cake")]
});

const OrderCake = Loadable({
    loader: () => import(/* webpackChunkName: "order_cake" */ "./order_cake"),
    loading: () => null,
    modules: ["order_cake"],
    webpack: () => [require.resolveWeak("./order_cake")]
});

const DetailCake = Loadable({
    loader: () => import(/* webpackChunkName: "detail_cake" */ "./detail_cake"),
    loading: () => null,
    modules: ["detail_cake"],
    webpack: () => [require.resolveWeak("./detail_cake")]
});

const Story = Loadable({
    loader: () => import(/* webpackChunkName: "story" */ "./story"),
    loading: () => null,
    modules: ["story"],
    webpack: () => [require.resolveWeak("./story")]
});

const Cart = Loadable({
    loader: () => import(/* webpackChunkName: "cart" */ "./cart"),
    loading: () => null,
    modules: ["cart"],
    webpack: () => [require.resolveWeak("./cart")]
});

const Checkout = Loadable({
    loader: () => import(/* webpackChunkName: "checkout" */ "./checkout"),
    loading: () => null,
    modules: ["checkout"],
    webpack: () => [require.resolveWeak("./checkout")]
});

const CheckoutReceipt = Loadable({
    loader: () => import(/* webpackChunkName: "receipt" */ "./checkout/receipt/"),
    loading: () => null,
    modules: ["receipt"],
    webpack: () => [require.resolveWeak("./checkout/receipt/")]
});

const CheckoutSuccess = Loadable({
    loader: () => import(/* webpackChunkName: "success" */ "./checkout/success"),
    loading: () => null,
    modules: ["success"],
    webpack: () => [require.resolveWeak("./checkout/success")]
});

const CheckoutCancel = Loadable({
    loader: () => import(/* webpackChunkName: "cancel" */ "./checkout/cancel"),
    loading: () => null,
    modules: ["cancel"],
    webpack: () => [require.resolveWeak("./checkout/cancel")]
});

const Dashboard = Loadable({
    loader: () => import(/* webpackChunkName: "dashboard" */ "./dashboard"),
    loading: () => null,
    modules: ["dashboard"],
    webpack: () => [require.resolveWeak("./dashboard")]
});

const Login = Loadable({
    loader: () => import(/* webpackChunkName: "login" */ "./login"),
    loading: () => null,
    modules: ["login"],
    webpack: () => [require.resolveWeak("./login")]
});

const Logout = Loadable({
    loader: () => import(/* webpackChunkName: "logout" */ "./logout"),
    loading: () => null,
    modules: ["logout"],
    webpack: () => [require.resolveWeak("./logout")]
});

const Profile = Loadable({
    loader: () => import(/* webpackChunkName: "profile" */ "./profile"),
    loading: () => null,
    modules: ["profile"],
    webpack: () => [require.resolveWeak("./profile")]
});

export default () => (
    <Switch>
        <Route exact path="/" component={Homepage} />
        {/* <Route exact path="/order" component={Order} /> */}
        <Route exact path="/order_cake" component={OrderCake} />
        <Route exact path="/order_cake/:cakeType" component={DetailCake} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/celebration_cake" component={CelebrationCake} />
        <Route exact path="/story" component={Story} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/checkout/receipt" component={CheckoutReceipt} />
        <Route exact path="/checkout/success" component={CheckoutSuccess} />
        <Route exact path="/checkout/cancel" component={CheckoutCancel} />

        <Route exact path="/profile/:id" component={Profile} />

        <AuthenticatedRoute exact path="/dashboard" component={Dashboard} />

        <UnauthenticatedRoute exact path="/login" component={Login} />
        <AuthenticatedRoute exact path="/logout" component={Logout} />

        <Route component={NotFound} />
    </Switch>
);
