import {
    isMondayFromTimestamp,
    convertUTCTimestampToYYMMDD,
    getYYMMDDFromNow,
    isMondayFromDate,
    isPSTTimeZone,
    convertLocalTimeToPST
} from "../common/common";
import moment from "moment";

export const selectNearestAvailableTime = (orderLimitArray) => {
    let nearestAvailableTimestamp;

    for (let l = 0; l < orderLimitArray.length; l++) {
        if (!orderLimitArray[l]) continue;

        if (isMondayFromTimestamp(orderLimitArray[l].timestamp)) {
            continue;
        }

        if (
            orderLimitArray[l].crepe_cake.allow_count > 0 ||
            orderLimitArray[l].box_cake.allow_count > 0 ||
            orderLimitArray[l].chiffon_cake.allow_count > 0 ||
            orderLimitArray[l].sessional_cake.allow_count > 0
        ) {
            nearestAvailableTimestamp = orderLimitArray[l].timestamp;
            break;
        }
    }

    return {
        pickupDate: new Date(
            convertUTCTimestampToYYMMDD(nearestAvailableTimestamp * 1000)
        ),
        timestamp: nearestAvailableTimestamp
    };
};

export const isSelectedDateAvailable = (timestamp, orderLimitTimestampMapper) => {
    if (!timestamp) return false;

    if (!orderLimitTimestampMapper[timestamp]) return false;

    if (isMondayFromTimestamp(timestamp)) {
        return false;
    }

    if (
        orderLimitTimestampMapper[timestamp].crepe_cake.allow_count > 0 ||
        orderLimitTimestampMapper[timestamp].box_cake.allow_count > 0 ||
        orderLimitTimestampMapper[timestamp].chiffon_cake.allow_count > 0 ||
        orderLimitTimestampMapper[timestamp].sessional_cake.allow_count > 0
    ) {
        return true;
    }

    return false;
};

export const showFlavors = (item) => {
    const flavor1 = item.flavor1;
    const flavor2 = item.flavor2;

    let flavorHTML = "";

    if (flavor1 != DEFAULT) {
        flavorHTML = flavorHTML + flavor1;
    }

    if (flavor2 != DEFAULT) {
        flavorHTML = flavorHTML + " & " + flavor2;
    }

    return flavorHTML;
};

export const DEFAULT = "default";

export const CREPE = "crepe";
export const BOX = "box";
export const SESSIONAL = "sessional";
export const MOLTEN = "molten";
export const CHIFFON = "chiffon";

// return true if date is not monday and sunday
export const checkMondaySunday = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    return day !== 0 && day !== 1;
};

export const checkDateLimit = (timestamp, orderLimitTimestampMapper) => {
    if (!orderLimitTimestampMapper[timestamp]) {
        return false;
    }

    const crepeCake = orderLimitTimestampMapper[timestamp].crepe_cake;
    const boxCake = orderLimitTimestampMapper[timestamp].box_cake;
    const chiffonCake = orderLimitTimestampMapper[timestamp].chiffon_cake;
    const sessionalCake = orderLimitTimestampMapper[timestamp].sessional_cake;

    const isAvailable =
        crepeCake?.allow_count > 0 ||
        boxCake?.allow_count > 0 ||
        chiffonCake?.allow_count > 0 ||
        sessionalCake?.allow_count > 0;
    return isAvailable;
};

export const filterDate = (date, orderLimitTimestampMapper) => {
    const m = moment(date).tz("UTC");
    let pickupTimestamp = m.unix();
    if (!isPSTTimeZone()) {
        console.log("here");
        pickupTimestamp = convertLocalTimeToPST(date);
    }

    if (!checkDateLimit(pickupTimestamp, orderLimitTimestampMapper)) {
        return false;
    }

    return !isMondayFromDate(date);
};

export const getClosestPickupDate = () => {
    return getYYMMDDFromNow(1);
};
