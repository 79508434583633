import React from "react";

const footerStyle = {
    color: "#9b9b9b",
    fontSize: "12px",
    paddingBottom: "20px"
};

const iconDefaultStyle = {
    paddingRight: "5px"
};

const a = {
    textDecoration: "none",
    color: "inherit"
};
export default () => (
    <React.Fragment>
        <footer style={footerStyle}>
            <div>Cake Designing Studio</div>
            <div>
                <i className="fab fa-instagram" style={iconDefaultStyle} />
                <a
                    style={a}
                    href="https://www.instagram.com/whatsmorecakeshop/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    whaT's More Cake
                </a>
            </div>
            <div>
                <i className="far fa-envelope" style={iconDefaultStyle} />
                <a
                    style={a}
                    href="mailto:<whatsmore cake>info@whatsmorestudio.com?subject=Cake Infomation"
                >
                    info@whatsmorestudio.com
                </a>
            </div>
            <div>
                <i className="fas fa-map-marker-alt" style={iconDefaultStyle} />
                <a
                    style={a}
                    href="https://goo.gl/maps/rVUsCYPkazzsSTwA8"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    500 Lawrence Expy, Sunnyvale, CA 94085
                </a>
            </div>
            <div>© 2023 whaT's More Cake</div>
        </footer>
    </React.Fragment>
);
