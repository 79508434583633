const defaultConfigsState = {
    new_order_flow_2020_enabled: true,
    loading: false,
    error: undefined
};

export default (state = defaultConfigsState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};
