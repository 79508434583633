export const SUNNYVALE = "sunnyvale";
export const SAN_MATEO = "san_mateo";
export const ALLOW_LOCATION = [SUNNYVALE, SAN_MATEO];

export const getLocationFromLocalStorage = () => {
    if (typeof window !== "undefined") {
        const location = window.localStorage.getItem("location");
        if (ALLOW_LOCATION.includes(location)) {
            return location;
        }
    }

    return SUNNYVALE;
};

export const setLocationToLocalStorage = (location) => {
    if (typeof window !== "undefined") {
        window.localStorage.setItem("location", location);
    }
};

export const hasSetLocation = () => {
    const location = window.localStorage.getItem("location");
    if (ALLOW_LOCATION.includes(location)) {
        return true;
    }
    return false;
};
